import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/ui/Hero/Hero"
import Section from "../components/ui/Section/Section"
import GdprDialogButton from "../components/Gdpr/GdprDialogButton"

const Datenschutz = ({ data }) => {
    return (
        <Layout
            crumbs={[
                { label: "Home", url: "" },
                { label: "Datenschutz", url: "datenschutz" },
            ]}
        >
            <Hero 
                title="Datenschutz" 
                subtitle={<><GdprDialogButton className="button is-primary is-rounded" /></>} 
                size="medium"
            />
            <Section>
                
                <div className="content">
                    <h2>
                        1. Name und Kontaktdaten des für die Verarbeitung
                        Verantwortlichen sowie des betrieblichen
                        Datenschutzbeauftragten
                    </h2>
                    <p>
                        Diese Datenschutzerklärung gilt für die
                        Datenverarbeitung durch:
                    </p>
                    <p>
                        Verantwortlicher: HIS Print & Service GmbH & Co. KG (im
                        Folgenden: HIS), Zur Schönhalde 1, 89352 Ellzee,
                        Telefon: +49 (0)8283/99810-0, Telefax: +49 (0)8283/
                        99810-12, E-Mail: info@his-print-service.de
                    </p>
                    <p>
                        Der betriebliche Datenschutzbeauftragte von HIS ist
                        unter der oben genannten Anschrift, zu Händen Herrn
                        Johannes Hoffmann, beziehungsweise unter
                        j.hoffmann@hisprint.de erreichbar.
                    </p>
                    <h2>
                        2. Erhebung und Speicherung personenbezogener Daten
                        sowie Art und Zweck von deren Verwendung
                    </h2>
                    <h3>a) Beim Besuch der Website</h3>
                    <p>
                        Beim Aufrufen unserer Website www.his-print-service.de
                        werden durch den auf Ihrem Endgerät zum Einsatz
                        kommenden Browser automatisch Informationen an den
                        Server unserer Website gesendet. Diese Informationen
                        werden temporär in einem sogenannten Logfile
                        gespeichert. Folgende Informationen werden dabei ohne
                        Ihr Zutun erfasst und bis zur automatisierten Löschung
                        gespeichert:
                    </p>
                    <ul>
                        <li>IP-Adresse des anfragenden Rechners,</li>
                        <li>Datum und Uhrzeit des Zugriffs,</li>
                        <li>Name und URL der abgerufenen Datei,</li>
                        <li>
                            Website, von der aus der Zugriff erfolgt
                            (Referrer-URL),
                        </li>
                        <li>
                            verwendeter Browser und ggf. das Betriebssystem
                            Ihres Rechners sowie der Name Ihres
                            Access-Providers.
                        </li>
                    </ul>
                    Die genannten Daten werden durch uns zu folgenden Zwecken
                    verarbeitet:
                    <ul>
                        <li>
                            Gewährleistung eines reibungslosen
                            Verbindungsaufbaus der Website,
                        </li>
                        <li>
                            Gewährleistung einer komfortablen Nutzung unserer
                            Website,
                        </li>
                        <li>
                            Auswertung der Systemsicherheit und -stabilität
                            sowie
                        </li>
                        <li>zu weiteren administrativen Zwecken.</li>
                    </ul>
                    <p>
                        Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6
                        Abs. 1 S. 1 lit. f DSG-VO. Unser berechtigtes Interesse
                        folgt aus oben aufgelisteten Zwecken zur Datenerhebung.
                        In keinem Fall verwenden wir die erhobenen Daten zu dem
                        Zweck, Rückschlüsse auf Ihre Person zu ziehen.
                    </p>
                    <p>
                        Darüber hinaus setzen wir beim Besuch unserer Website
                        Cookies sowie Analysedienste ein. Nähere Erläuterungen
                        dazu erhalten Sie unter den Ziff. 4 und 5 dieser
                        Datenschutzerklärung.
                    </p>
                    <h3>b) Bei Anmeldung für unseren Newsletter</h3>
                    <p>
                        Sofern Sie nach Art. 6 Abs. 1 S. 1 lit. a DSGVO
                        ausdrücklich eingewilligt haben, verwenden wir Ihre
                        E-Mail-Adresse dafür, Ihnen regelmäßig unseren
                        Newsletter zu übersenden. Für den Empfang des
                        Newsletters ist die Angabe einer E-Mail-Adresse
                        ausreichend.
                    </p>
                    <p>
                        Die Abmeldung ist jederzeit möglich, zum Beispiel über
                        einen Link am Ende eines jeden Newsletters. Alternativ
                        können Sie Ihren Abmeldewunsch gerne auch jederzeit an
                        info@his-print-service.de per E-Mail senden.
                    </p>
                    <p>
                        Wir verwenden den Google-Dienst reCaptcha, um festzustellen, ob ein Mensch oder ein Computer eine bestimmte Eingabe in unserem Kontakt- oder Newsletter-Formular macht. Google prüft anhand folgender Daten, ob Sie ein Mensch oder ein Computer sind: IP-Adresse des verwendeten Endgeräts, die Webseite, die Sie bei uns besuchen und auf der das Captcha eingebunden ist, das Datum und die Dauer des Besuchs, die Erkennungsdaten des verwendeten Browser- und Betriebssystem-Typs, Google-Account, wenn Sie bei Google eingeloggt sind, Mausbewegungen auf den reCaptcha-Flächen sowie Aufgaben, bei denen Sie Bilder identifizieren müssen. Rechtsgrundlage für die beschriebene Datenverarbeitung ist Art. 6 Abs. 1 lit. f Datenschutz-Grundverordnung. Es besteht ein berechtigtes Interesse auf unserer Seite an dieser Datenverarbeitung, die Sicherheit unserer Webseite zu gewährleisten und uns vor automatisierten Eingaben (Angriffen) zu schützen.
                    </p>
                    <h3>c) Bei Nutzung unseres Kontaktformulars</h3>
                    <p>
                        Bei Fragen jeglicher Art bieten wir Ihnen die
                        Möglichkeit, mit uns über ein auf der Website
                        bereitgestelltes Formular Kontakt aufzunehmen. Dabei ist
                        die Angabe einer gültigen E-Mail-Adresse erforderlich,
                        damit wir wissen, von wem die Anfrage stammt und um
                        diese beantworten zu können. Weitere Angaben können
                        freiwillig getätigt werden.
                    </p>
                    <p>
                        Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit
                        uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf
                        Grundlage Ihrer freiwillig erteilten Einwilligung.
                    </p>
                    <p>
                        Die für die Benutzung des Kontaktformulars von uns
                        erhobenen personenbezogenen Daten werden nach Erledigung
                        der von Ihnen gestellten Anfrage automatisch gelöscht.
                    </p>
                    <h3>d) Bei dem hochladen von Druckdateien</h3>
                    <p>
                        Das hochladen über das Formular auf der Website übergibt Ihre Daten
                        an unseren Server, der diese anonymisiert zwischenspeichert. Erfolgt keine Anfrage
                        über unsere Anfragefunktion werden die Dateien innerhalb 24 Stunden automatisch
                        gelöscht. Wird eine Anfrage über unsere Anfragefunktion gestellt werden die anonymisierten
                        Druckdateien an die Personenbezogenen Daten der Anfrage zur weiteren
                        Verarbeitung angehängt.
                    </p>
                    <h3>e) Bei Aufgabe von Anfragen</h3>
                    <p>
                        Wir erheben personenbezogene Daten, wenn Sie uns diese
                        im Rahmen Ihrer Anfrage mitteilen. Pflichtfelder
                        werden als solche gekennzeichnet, da wir in diesen
                        Fällen die Daten zwingend zur Angebotsabwicklung
                        benötigen und Sie ohne deren Angabe die Anfrage nicht
                        abschließen können. Welche Daten erhoben werden, ist aus
                        den jeweiligen Eingabeformularen ersichtlich. Wir
                        verwenden die von ihnen mitgeteilten Daten zur
                        Angebotsabwicklung. Nach vollständiger Abwicklung des
                        Angebots werden Ihre Daten für die eventuelle weitere 
                        Verarbeitung gespeichert. Ergibt sich keine Bestellung
                        aus den Daten werden diese anonymisiert und personenbezogene
                        Informationen gelöscht, falls diese nicht mit anderen Anfragen oder
                        Bestellungen verbunden sind.
                    </p>
                    <h3>f) Bei Aufgabe von Bestellungen</h3>
                    <p>
                        Wir erheben personenbezogene Daten, wenn Sie uns diese
                        im Rahmen Ihrer Bestellung mitteilen. Pflichtfelder
                        werden als solche gekennzeichnet, da wir in diesen
                        Fällen die Daten zwingend zur Vertragsabwicklung
                        benötigen und Sie ohne deren Angabe die Bestellung nicht
                        abschließen können. Welche Daten erhoben werden, ist aus
                        den jeweiligen Eingabeformularen ersichtlich. Wir
                        verwenden die von ihnen mitgeteilten Daten zur
                        Vertragsabwicklung. Nach vollständiger Abwicklung des
                        Vertrages werden Ihre Daten für die weitere Verarbeitung
                        eingeschränkt und nach Ablauf etwaiger steuer- und
                        handelsrechtlichen Aufbewahrungsfristen gelöscht, sofern
                        Sie nicht ausdrücklich in eine weitere Nutzung Ihrer
                        Daten eingewilligt haben oder wir uns eine darüber
                        hinausgehende Datenverwendung vorbehalten, die
                        gesetzlich erlaubt ist und über die wir Sie in dieser
                        Erklärung informieren.
                    </p>
                    <h2>3. Weitergabe von Daten</h2>
                    <p>
                        Eine Übermittlung Ihrer persönlichen Daten an Dritte zu
                        anderen als den im Folgenden aufgeführten Zwecken findet
                        nicht statt.
                    </p>
                    <p>
                        Wir geben Ihre persönlichen Daten nur an Dritte weiter,
                        wenn:
                    </p>
                    <ul>
                        <li>
                            Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO
                            ausdrückliche Einwilligung dazu erteilt haben,
                        </li>
                        <li>
                            die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO
                            zur Geltendmachung, Ausübung oder Verteidigung von
                            Rechtsansprüchen erforderlich ist und kein Grund zur
                            Annahme besteht, dass Sie ein überwiegendes
                            schutzwürdiges Interesse an der Nichtweitergabe
                            Ihrer Daten haben,
                        </li>
                        <li>
                            für den Fall, dass für die Weitergabe nach Art. 6
                            Abs. 1 S. 1 lit. c DSGVO eine gesetzliche
                            Verpflichtung besteht, sowie
                        </li>
                        <li>
                            dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1
                            lit. b DSGVO für die Abwicklung von
                            Vertragsverhältnissen mit Ihnen erforderlich ist.
                        </li>
                    </ul>
                    <p>
                        Zur Vertragserfüllung geben wir Ihre Daten an das mit
                        der Lieferung beauftragte Versandunternehmen weiter,
                        soweit dies zur Lieferung bestellter Waren erforderlich
                        ist. Je nach dem, welchen Zahlungsdienstleister Sie im
                        Bestellprozess auswählen, geben wir zur Abwicklung von
                        Zahlungen die hierfür erhobenen Zahlungsdaten an das mit
                        der Zahlung beauftragte Kreditinstitut und ggf. von uns
                        beauftragte Zahlungsdienstleister bzw. an den
                        ausgewählten Zahlungsdienst weiter. Zum Teil erheben die
                        ausgewählten Zahlungsdienstleister diese Daten auch
                        selbst, soweit Sie dort ein Konto anlegen. In diesem
                        Fall müssen Sie sich im Bestellprozess mit Ihren
                        Zugangsdaten bei dem Zahlungsdienstleister anmelden. Es
                        gilt insoweit die Datenschutzerklärung des jeweiligen
                        Zahlungsdienstleisters.
                    </p>
                    <h2>4. Cookies</h2>
                    <p>
                        Wir setzen auf unserer Seite Cookies ein. Hierbei
                        handelt es sich um kleine Dateien, die Ihr Browser
                        automatisch erstellt und die auf Ihrem Endgerät (Laptop,
                        Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie
                        unsere Seite besuchen. Cookies richten auf Ihrem
                        Endgerät keinen Schaden an und enthalten keine Viren,
                        Trojaner oder sonstige Schadsoftware.
                    </p>
                    <p>
                        In dem Cookie werden Informationen abgelegt, die sich
                        jeweils im Zusammenhang mit dem spezifisch eingesetzten
                        Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir
                        dadurch unmittelbar Kenntnis von Ihrer Identität
                        erhalten.
                    </p>
                    <p>
                        Der Einsatz von Cookies dient einerseits dazu, die
                        Nutzung unseres Angebots für Sie angenehmer zu
                        gestalten. So setzen wir sogenannte Session-Cookies ein,
                        um zu erkennen, dass Sie einzelne Seiten unserer Website
                        bereits besucht haben. Diese werden nach Verlassen
                        unserer Seite automatisch gelöscht.
                    </p>
                    <p>
                        Darüber hinaus setzen wir ebenfalls zur Optimierung der
                        Benutzerfreundlichkeit temporäre Cookies ein, die für
                        einen bestimmten festgelegten Zeitraum auf Ihrem
                        Endgerät gespeichert werden. Besuchen Sie unsere Seite
                        erneut, um unsere Dienste in Anspruch zu nehmen, wird
                        automatisch erkannt, dass Sie bereits bei uns waren und
                        welche Eingaben und Einstellungen sie getätigt haben, um
                        diese nicht noch einmal eingeben zu müssen.
                    </p>
                    <p>
                        Zum anderen setzten wir Cookies ein, um die Nutzung
                        unserer Website statistisch zu erfassen und zum Zwecke
                        der Optimierung unseres Angebotes für Sie auszuwerten
                        (siehe Ziff. 5). Diese Cookies ermöglichen es uns, bei
                        einem erneuten Besuch unserer Seite automatisch zu
                        erkennen, dass Sie bereits bei uns waren. Diese Cookies
                        werden nach einer jeweils definierten Zeit automatisch
                        gelöscht.
                    </p>
                    <p>
                        Die durch Cookies verarbeiteten Daten sind für die
                        genannten Zwecke zur Wahrung unserer berechtigten
                        Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1
                        lit. f DSGVO erforderlich.
                    </p>
                    <p>
                        Die meisten Browser akzeptieren Cookies automatisch. Sie
                        können Ihren Browser jedoch so konfigurieren, dass keine
                        Cookies auf Ihrem Computer gespeichert werden oder stets
                        ein Hinweis erscheint, bevor ein neuer Cookie angelegt
                        wird. Die vollständige Deaktivierung von Cookies kann
                        jedoch dazu führen, dass Sie nicht alle Funktionen
                        unserer Website nutzen können. Jeder Browser
                        unterscheidet sich in der Art, wie er die
                        Cookie-Einstellungen verwaltet. Diese ist in dem
                        Hilfemenü jedes Browsers beschrieben, welches Ihnen
                        erläutert, wie Sie Ihre Cookie-Einstellungen ändern
                        können. Diese finden Sie für die jeweiligen Browser
                        unter den folgenden Links:
                    </p>
                    <ul>
                        <li>
                            Internet Edge: https://support.microsoft.com/de-de/help/4027947/microsoft-edge-delete-cookies
                        </li>
                        <li>
                            Safari™: https://support.apple.com/de-de/guide/safari/sfri11471/mac
                        </li>
                        <li>
                            Chrome™: http://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647
                        </li>
                        <li>
                            Firefox™ https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
                        </li>
                        <li>
                            Opera™ : http://help.opera.com/Windows/10.20/de/cookies.html
                        </li>
                    </ul>
                    <h2>5. Einbinden von Diensten und Inhalten Dritter</h2>
                    <h3>a) Youtube</h3>
                    <p>
                        Wir binden Videos der Plattform "YouTube" des Anbieters
                        Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
                        94043, USA, ein. Die Datenschutzerklärung von Youtube
                        findet sich unter:
                        https://www.google.com/policies/privacy/, die
                        Opt-Out-Möglichkeit von Youtube findet sich unter:
                        https://adssettings.google.com/authenticated.
                    </p>
                    <h3>b) Google Maps</h3>
                    <p>
                        Wir binden Landkarten des Dienstes "Google Maps" des
                        Anbieters Google LLC, 1600 Amphitheatre Parkway,
                        Mountain View, CA 94043, USA, ein. Zu den verarbeiteten
                        Daten können insbesondere IP-Adressen und Standortdaten
                        der Nutzer gehören, die jedoch nicht ohne deren
                        Einwilligung (im Regelfall im Rahmen der Einstellungen
                        ihrer Endgeräte vollzogen), erhoben werden. Die Daten
                        können in den USA verarbeitet werden. Die
                        Datenschutzerklärung von Google Maps findet sich unter:
                        https://www.google.com/policies/privacy/, die
                        Opt-Out-Möglichkeit von Google Maps findet sich unter:
                        https://adssettings.google.com/authenticated.
                    </p>
                    <h3>c) Cleverreach</h3>
                    <p>
                        Für den Versand von Newslettern nutzen wir CleverReach. Anbieter ist die CleverReach GmbH & Co. KG, 
                        Mühlenstr. 43, 26180 Rastede. Mit diesem Dienst können wir den Newsletterversand organisieren und analysieren. 
                        Ihre für den Bezug des Newsletters eingegebenen Daten, wie beispielsweise Ihre E-Mail-Adresse, werden auf den 
                        Servern von CleverReach gespeichert. Serverstandorte sind Deutschland bzw. Irland.
                    </p>
                    <p>
                        Der Newsletter-Versand mit CleverReach gestattet es uns, das Verhalten des Newsletterempfängers zu analysieren. 
                        Die Analyse legt u.a. dar, wie viele Empfänger ihren Newsletter geöffnet haben und mit welcher Häufigkeit Links im Newsletter 
                        geklickt wurden. CleverReach unterstützt Conversion-Tracking, um damit zu analysieren, ob nach Klick auf einen Link eine 
                        zuvor definierte Aktion, wie beispielsweise ein Produktkauf, erfolgt ist. Einzelheiten zur Datenanalyse durch CleverReach 
                        finden Sie unter: https://www.cleverreach.com/de/funktionen/reporting-und-tracking/.
                    </p>
                    <p>
                        Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit 
                        möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail oder Sie melden sich über den "Austragen"-Link im Newsletter ab. Die 
                        Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                    </p>
                    <p>
                        Wünschen Sie keine Analyse durch CleverReach, müssen Sie den Newsletter abbestellen. Für die Abbestellung genügt eine formlose 
                        Mitteilung per E-Mail an uns oder Sie melden sich über den "Austragen"-Link im Newsletter ab.
                    </p>
                    <p>
                        Zur Einrichtung des Abonnements eingegebene Daten werden im Falle der Abmeldung von unseren Servern und den Servern von 
                        CleverReach gelöscht. Sollten diese Daten für andere Zwecke und an anderer Stelle an uns übermittelt worden sein, verbleiben diese weiterhin bei uns.
                    </p>
                    <p>
                        Einzelheiten zu den Datenschutzbestimmungen von CleverReach finden Sie unter: https://www.cleverreach.com/de/datenschutz/.
                    </p>
                    <p>
                        Auftragsverarbeitung

                        Zur vollständigen Erfüllung der gesetzlichen Datenschutzvorgaben haben wir mit CleverReach einen Vertrag über die Auftragsverarbeitung abgeschlossen.
                    </p>
                    <h3>d) Vimeo</h3>
                    <p>
                        Für Integration und Darstellung von Videoinhalten nutzt unsere Website Plugins von Vimeo. Anbieter des Videoportals ist die Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.

                        Bei Aufruf einer Seite mit integriertem Vimeo-Plugin wird eine Verbindung zu den Servern von Vimeo hergestellt. Vimeo erfährt hierdurch, welche unserer Seiten Sie aufgerufen haben. Vimeo erfährt Ihre IP-Adresse, selbst wenn Sie nicht beim Videoportal eingeloggt sind oder dort kein Konto besitzen. Es erfolgt eine Übermittlung der von Vimeo erfassten Informationen an Server des Videoportals in den USA.

                        Vimeo kann Ihr Surfverhalten direkt Ihrem persönlichen Profil zuordnen. Durch vorheriges Ausloggen haben Sie die Möglichkeit, dies zu unterbinden.

                        Einzelheiten zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Vimeo unter: https://vimeo.com/privacy.
                    </p>
                    <h3>e) Matomo</h3>
                    <p>
                        Unsere Website verwendet den Webanalysedienst Matomo. Matomo ist eine Open Source Lösung.

                        Matomo verwendet "Cookies." Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert und die eine Analyse der Website-Benutzung ermöglichen. Mittels Cookie erzeugte Informationen über die Benutzung unserer Website werden auf unserem Server gespeichert. Vor der Speicherung erfolgt eine Anonymisierung Ihrer IP-Adresse.

                        Cookies von Matomo verbleiben auf Ihrem Endgerät, bis Sie eine Löschung vornehmen.

                        Das Setzen von Matomo-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der anonymisierten Analyse des Nutzerverhaltens, um sowohl unser Webangebot und ggf. auch Werbung zu optimieren.

                        Es erfolgt keine Weitergabe der im Matomo-Cookie gespeicherten Informationen über die Benutzung dieser Website. Das Setzen von Cookies durch Ihren Webbrowser ist verhinderbar. Einige Funktionen unserer Website könnten dadurch jedoch eingeschränkt werden.

                        Sie können hier die Speicherung und Nutzung Ihrer Daten deaktivieren. Ihr Browser setzt ein Opt-Out-Cookie, welches die Speicherung von Matomo Nutzungsdaten unterbindet. Wenn Sie Ihre Cookies löschen, wird auch das Matomo Opt-Out-Cookie entfernt. Bei einem erneuten Besuch unserer Website ist das Opt-Out-Cookie zur Unterbindung der Speicherung und Nutzung Ihrer Daten erneut zu setzen.

                        <iframe
                            style={{border: 0, height: "200px", width: "600px"}}
                            src="https://analytics.his-print-service.de/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=4a4a4a&fontSize=16px&fontFamily=Montserrat"
                        ></iframe>
                    </p>
                    <h2>6. Betroffenenrechte</h2>
                    <p>Sie haben das Recht:</p>
                    <ul>
                        <li>
                            gemäß Art. 15 DSGVO Auskunft über Ihre von uns
                            verarbeiteten personenbezogenen Daten zu verlangen.
                            Insbesondere können Sie Auskunft über die
                            Verarbeitungszwecke, die Kategorien der
                            personenbezogenen Daten, die Kategorien von
                            Empfängern, gegenüber denen Ihre Daten offengelegt
                            wurden oder werden, die geplante Speicherdauer, das
                            Bestehen eines Rechts auf Berichtigung, Löschung,
                            Einschränkung der Verarbeitung oder Widerspruch, das
                            Bestehen eines Beschwerderechts, die Herkunft ihrer
                            Daten, sofern diese nicht bei uns erhoben wurden,
                            sowie über das Bestehen einer automatisierten
                            Entscheidungsfindung einschließlich Profiling und
                            ggf. aussagekräftigen Informationen zu deren
                            Einzelheiten verlangen;
                        </li>
                        <li>
                            gemäß Art. 16 DSGVO unverzüglich die Berichtigung
                            unrichtiger oder Vervollständigung Ihrer bei uns
                            gespeicherten personenbezogenen Daten zu verlangen;
                        </li>
                        <li>
                            gemäß Art. 17 DSGVO die Löschung Ihrer bei uns
                            gespeicherten personenbezogenen Daten zu verlangen,
                            soweit nicht die Verarbeitung zur Ausübung des
                            Rechts auf freie Meinungsäußerung und Information,
                            zur Erfüllung einer rechtlichen Verpflichtung, aus
                            Gründen des öffentlichen Interesses oder zur
                            Geltendmachung, Ausübung oder Verteidigung von
                            Rechtsansprüchen erforderlich ist;
                        </li>
                        <li>
                            gemäß Art. 18 DSGVO die Einschränkung der
                            Verarbeitung Ihrer personenbezogenen Daten zu
                            verlangen, soweit die Richtigkeit der Daten von
                            Ihnen bestritten wird, die Verarbeitung unrechtmäßig
                            ist, Sie aber deren Löschung ablehnen und wir die
                            Daten nicht mehr benötigen, Sie jedoch diese zur
                            Geltendmachung, Ausübung oder Verteidigung von
                            Rechtsansprüchen benötigen oder Sie gemäß Art. 21
                            DSGVO Widerspruch gegen die Verarbeitung eingelegt
                            haben;
                        </li>
                        <li>
                            gemäß Art. 20 DSGVO Ihre personenbezogenen Daten,
                            die Sie uns bereitgestellt haben, in einem
                            strukturierten, gängigen und maschinenlesebaren
                            Format zu erhalten oder die Übermittlung an einen
                            anderen Verantwortlichen zu verlangen;
                        </li>
                        <li>
                            gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte
                            Einwilligung jederzeit gegenüber uns zu widerrufen.
                            Dies hat zur Folge, dass wir die Datenverarbeitung,
                            die auf dieser Einwilligung beruhte, für die Zukunft
                            nicht mehr fortführen dürfen und
                        </li>
                        <li>
                            gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde
                            zu beschweren. In der Regel können Sie sich hierfür
                            an die Aufsichtsbehörde Ihres üblichen
                            Aufenthaltsortes oder Arbeitsplatzes oder unseres
                            Unternehmenssitzes wenden.
                        </li>
                    </ul>
                    <h2>7. Widerspruchsrecht</h2>
                    <p>
                        Sofern Ihre personenbezogenen Daten auf Grundlage von
                        berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f
                        DSGVO verarbeitet werden, haben Sie das Recht, gemäß
                        Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer
                        personenbezogenen Daten einzulegen, soweit dafür Gründe
                        vorliegen, die sich aus Ihrer besonderen Situation
                        ergeben oder sich der Widerspruch gegen Direktwerbung
                        richtet. Im letzteren Fall haben Sie ein generelles
                        Widerspruchsrecht, das ohne Angabe einer besonderen
                        Situation von uns umgesetzt wird.
                    </p>
                    <p>
                        Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht
                        Gebrauch machen, genügt eine E-Mail an
                        w.hoffmann@his-print-service.de.
                    </p>
                    <h2>8. Datensicherheit</h2>
                    <p>
                        Wir verwenden innerhalb des Website-Besuchs das
                        verbreitete SSL-Verfahren (Secure Socket Layer) in
                        Verbindung mit der jeweils höchsten
                        Verschlüsselungsstufe, die von Ihrem Browser unterstützt
                        wird. In der Regel handelt es sich dabei um eine 256 Bit
                        Verschlüsselung. Falls Ihr Browser keine 256-Bit
                        Verschlüsselung unterstützt, greifen wir stattdessen auf
                        128-Bit v3 Technologie zurück. Ob eine einzelne Seite
                        unseres Internetauftrittes verschlüsselt übertragen
                        wird, erkennen Sie an der geschlossenen Darstellung des
                        Schüssel- beziehungsweise Schloss-Symbols in der unteren
                        Statusleiste Ihres Browsers.
                    </p>
                    <p>
                        Wir bedienen uns im Übrigen geeigneter technischer und
                        organisatorischer Sicherheitsmaßnahmen, um Ihre Daten
                        gegen zufällige oder vorsätzliche Manipulationen,
                        teilweisen oder vollständigen Verlust, Zerstörung oder
                        gegen den unbefugten Zugriff Dritter zu schützen. Unsere
                        Sicherheitsmaßnahmen werden entsprechend der
                        technologischen Entwicklung fortlaufend verbessert.
                    </p>
                    <h2>
                        9. Aktualität und Änderung dieser Datenschutzerklärung
                    </h2>
                    <p>
                        Diese Datenschutzerklärung ist aktuell gültig und hat
                        den Stand März 2020. Durch die Weiterentwicklung unserer
                        Website und Angebote darüber oder aufgrund geänderter
                        gesetzlicher beziehungsweise behördlicher Vorgaben kann
                        es notwendig werden, diese Datenschutzerklärung zu
                        ändern. Die jeweils aktuelle Datenschutzerklärung kann
                        jederzeit auf der Website unter
                        https://www.his-print-service.de/datenschutz von Ihnen
                        abgerufen und ausgedruckt werden.
                    </p>
                </div>
            </Section>
        </Layout>
    )
}

export default Datenschutz;
